.modal_login_card {
  width: 100%;
  padding: 0px 10px;
}
.numberLoginInput {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #ced4da;
  background-color: #fff;
  font-size: 18px;
  padding: 5px 0px;
  margin: 10px 0px;
}
.login-with-email-container,
#LoginWithPhoneNoToggleBtn {
  display: none;
}
#LoginWithEmailToggleBtn,
#LoginWithPhoneNoToggleBtn {
  color: #707070;
  border: 1px solid gainsboro;
  background: none;
  width: 100%;
  padding: 10px 0px;
  margin: 30px 0px 10px 0px;
  transition: all 0.2s;
  font-weight: 600;
}
#LoginWithEmailToggleBtn i,
#LoginWithPhoneNoToggleBtn i {
  font-size: 20px;
  margin-right: 5px;
  color: #ef8341;
}
.login_input_form {
  display: block;
  width: 100%;
  padding: 4px 0px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid #ced4da;
  appearance: none;
  outline: none;
}

.signin_btn {
  color: #fff;
  background: #ef8341;
  border: none;
  width: 100%;
  padding: 10px 20px;
  margin: 20px 0px 10px 0px;
  transition: all 0.2s;
}
.signin_btn:hover {
  background: #666666;
}

.sign_up_path a {
  color: white;
  text-decoration: none;
  background: #212529;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.needAccountToggleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.needAccountToggleBtn a {
  margin-left: 10px;
  margin-top: -5px;
  font-weight: 600;
  color: #ef8341;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 400px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.passwordToggleBtn {
  z-index: 999;
  margin-top: 5px;
  margin-left: -20px;
  font-size: 20px;
}

.otp_box{
  display: none;
  text-align: center;
}

#otpSubmitBtn{
  color: #fff;
  background: #ef8341;
  border: none;
  width: 100%;
  padding: 10px 20px;
  margin: 20px 0px;
  transition: all 0.2s;
}
.countdown-text{
  display: flex;
  margin: 10px 0px 0px 0px;
}
.countdown-text p{
  margin-bottom: 0px !important;
}
.resendOtpBtn{
  color: #ffffff;
  background: #ef8341;
  border: none;
  border-radius: 3px;
  padding: 0px 5px;
  margin-left: 10px;
  transition: all 0.2s;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .modal_login_card {
    width: 100%;
    padding: 0px 20px;
  }
}
@media screen and (max-width: 668px) {
  /* .modal_login_card {
    width: 90%;
  } */
}
@media screen and (max-width: 576px) {
  /* .modal_login_card {
    width: 80%;
  } */
  .login_input_form {
    width: 100%;
    padding: 4px 10px;
    font-size: 20px;
  }
}
@media screen and (max-width: 450px) {
  /* .modal_login_card {
    width: 75%;
  } */
  .signin_btn {
    width: 100%;
  }
}
@media screen and (max-width: 360px) {
  /* .modal_login_card {
    width: 70%;
  } */
}
