.header_showcase_section_container {
  display: grid;
  grid-template-columns: repeat(4, minmax(20%, auto));
  grid-gap: 15px;
  overflow: hidden;
}
.header_showcase_section_container > *:first-child {
  grid-column: span 1;
}
.header_showcase_section_container > *:nth-child(2) {
  grid-column: span 3;
}
.header_showcase_section_container > *:first-child,
.features-main .grid > *:nth-child(2) {
  grid-column: 1;
}

.sidebar_container {
  width: 100%;
  height: 400px;
  /* border: 1px solid gainsboro; */
  /* border-radius: 7px; */
  /* padding: 5px 0px; */
}

.sidebar_container::-webkit-scrollbar {
  display: none;
}

.sidebar_container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.header_showcase_section_banner {
  height: 400px;
  border: 1px solid gainsboro;
  border-radius: 7px;
  overflow: hidden;
}
.header_showcase_section_banner .slider img{
  height: 400px;
}

@media screen and (max-width: 768px) {
  .header_showcase_section_container {
    display: grid;
    grid-gap: 0px;
    overflow: hidden;
  }
  .slick-current{
    object-fit: cover;
  }
  .header_showcase_section_container > *:nth-child(2) {
    grid-column: span 4;
  }

  .sidebar_container{
    display: none;
  }

  .header_showcase_section_banner {
    height: auto;
  }
  .header_showcase_section_banner .slider img{
    height: auto;
  }
  
}