.top_rated_container {
  padding-top: 10px;
}
.top_rated_container_header{
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.top_rated_container h1 {
  font-size: 1.75rem;
}
.top_rated_container_header select {
  outline: none;
  border: 1px solid gainsboro;
  color: #4e4e4e;
}