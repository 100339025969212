
.product-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  min-height: 30vh;
  /* position: relative; */
}
.product-content::-webkit-scrollbar {
  display: none;
}
.product-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* no product content */
.no_product_content {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeletonLoader {
  height: 335px;
  z-index: -1;
}

/* -----Responsive Design----- */
@media screen and (max-width: 1400px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-gap: 20px; */
  }
}
@media screen and (max-width: 1300px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* grid-gap: 20px; */
  }
}

@media screen and (max-width: 1200px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 20px; */
  }
}

@media screen and (max-width: 992px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 20px; */
  }
}
@media screen and (max-width: 892px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 20px; */
  }
}

@media screen and (max-width: 768px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 10px ; */
  }
  .skeletonLoader {
    height: 300px;
  }
  .breadcrumb {
    font-size: 14px;
  }
}

@media screen and (max-width: 676px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 24px; */
  }
  .skeletonLoader {
    height: 240px;
  }
}
@media screen and (max-width: 576px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 24px; */
  }
  /* .skeletonLoader{
    height: 240px;
  } */
}

@media screen and (max-width: 476px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 24px; */
  }
  .skeletonLoader {
    height: 235px;
  }
}
@media screen and (max-width: 360px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 24px; */
  }
}
@media screen and (max-width: 300px) {
  .product-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 24px; */
    margin: 0px auto;
  }
}
