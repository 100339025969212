.track_order_img{
    height: 80px;
    width: 80px;
}
.track_order_amount_container {
    background: #f5faff;
    padding: 15px;
    margin: 3px;
}
.track_amount_value{
    font-size: 18px;
    font-weight: 500;
}