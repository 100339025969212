.cart {
  width: 80px;
  position: fixed;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  border: 2px solid #ff8800;
  border-radius: 5px;
  bottom: 45vh;
  margin-left: 94vw;
  padding: 10px 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
.cartIcon {
  font-size: 25px;
}
.cart i {
  margin-right: 5px;
  color: #ef8341;
}
.itemsForFullScreen {
  font-size: 12px;
}
.cart small {
  font-size: 13px;
}
.cartDetailsView-container,
.start-shopping-btn,
.itemsForResScreen {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .cart {
    margin-left: 90%;
  }
}

@media only screen and (max-width: 1050px) {
  .cart {
    width: 100%;
    bottom: 0px;
    margin-left: 0;
    padding: 0px;
    border-radius: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid #d1d1d1;
    z-index: 9999;
  }
  .start-shopping-btn {
    display: block;
    background: #ef8341;
    color: #fff;
    border: none;
    padding: 20px 0px;
    font-size: 17px;
    font-weight: 500;
  }
  .cartTotalPrice,
  .itemsForFullScreen {
    display: none;
  }
  .cartIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 30px;
  }
 
  .itemsForResScreen {
    display: block;
    position: absolute;
    margin-top: -20px;
    margin-left: 30px;
    background: #ef8341;
    color: #fff;
    width: 23px;
    height: 23px;
    border-radius: 100px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* @media only screen and (max-width: 768px) {
  .cart {
    bottom: 0px;
    margin-left: 0;
    padding: 0px;
    border-radius: 0px;
    width: 100%;
    display: flex;
  }
  .start-shopping-btn {
    display: block;
    background: #ef8341;
    color: #fff;
    border: none;
    padding: 20px 0px;
    font-weight: 500;
  }
  .cartTotalPrice, .itemsForFullScreen {
    display: none;
  }
  .cartIcon{
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cartIcon img{
    margin-top: 20px;
  }
  .itemsForResScreen{
    display: block;
    position: absolute;
    margin-top: -20px;
    margin-left: 40px;
    background: #ef8341;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 468px) {
  .cartIcon{
    margin-left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 400px) {
  .cartIcon{
    margin-left: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}
@media only screen and (max-width: 350px) {
  .cartIcon{
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
@media only screen and (max-width: 320px) {
  .cartIcon{
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 300px) {
  .cartIcon{
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
} */
