.recover_pass_card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.085);
    border-radius: .4375rem;
}
.recover_pass_card_body {
    min-height: 150px;
    border: none;
    padding: 15px 15px;
}
.recover_pass_input {
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    outline: none;
}
.recover_pass_btn {
    color: #fff;
    background: #ef8341;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px 0px;
}