.shop_cart_title{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}
.shop_cart_container {
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.shipping_delivary_form_input {
    width: 100%;
    margin: 5px;
    padding: 6px 15px;
    outline: none;
    border: 1px solid #ede8e8;
}
.continue_shoping_btn{
    background: red;
}
.continue_checkout_btn{
    background: blue;
}
.shopCartMinusBtn{
    padding: 10px;
    color: red;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}
.shopCartPlusBtn{
    padding: 10px;
    color: green;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}
.proceed_payment_btn{
    width: 100%;
    border: none;
    outline: none;
    color: #fff;
}
.shopCartEmptyAlert{
    color: red;
    font-weight: 500;
    margin-top: 20px;
}