/* --------Catagory container design--------- */
.categoryView-section h1 {
  font-size: 1.5rem;
}

.categoryView-container {
  width: 100%;
  margin: auto;
}

.category_content {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}

.card {
  height: auto;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Kanit", sans-serif;
  border: 1px solid rgb(230, 230, 230);
  overflow: hidden;
  transition: all 0.3s;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card-body,
.category_card-body {
  border: none;
}
.card-body img,
.category_card-body img {
  width: 100%;
  /* padding: 10px */
}
.card-footer {
  background: #eeeeee;
  color: #333333;
  font-size: 12px;
  text-align: center;
  border-top: 1px solid gainsboro;
}

/* -----Responsive Design----- */

@media screen and (max-width: 1500px) {
  .category_content {
    grid-template-columns: repeat(4, 1fr);
  }
  .card {
    height: 300px;
    font-size: 14px;
  }
  .card-body,
  .category_card-body {
    height: 255px;
    border: none;
  }
  .card-body img,
  .category_card-body img {
    width: 100%;
    height: 100%;
  }
  .card-footer {
    font-size: 14px;
    padding: 12px 10px;
  }
}

@media screen and (max-width: 1200px) {
  .category_content {
    grid-template-columns: repeat(4, 1fr);
  }
  .card {
    height: 280px;
    font-size: 14px;
  }
  .card-body,
  .category_card-body {
    height: 235px;
    border: none;
  }
  .card-body img,
  .category_card-body img {
    width: 100%;
    height: 100%;
  }
  .card-footer {
    font-size: 14px;
    padding: 12px 10px;
  }
}
@media screen and (max-width: 1100px) {
  .category_content {
    grid-template-columns: repeat(4, 1fr);
  }
  .card {
    height: 280px;
    font-size: 14px;
  }
  .card-body,
  .category_card-body {
    height: 235px;
    border: none;
  }
  .card-body img,
  .category_card-body img {
    width: 100%;
    height: 100%;
  }
  .card-footer {
    font-size: 14px;
    padding: 12px 10px;
  }
}
@media screen and (max-width: 1050px) {
  .category_content {
    grid-template-columns: repeat(4, 1fr);
  }
  .card {
    height: 280px;
    font-size: 14px;
  }
  .card-body,
  .category_card-body {
    height: 235px;
    border: none;
  }
  .card-body img,
  .category_card-body img {
    width: 100%;
    height: 100%;
  }
  .card-footer {
    font-size: 14px;
    padding: 12px 10px;
  }
}

@media screen and (max-width: 992px) {
  .category_content {
    grid-template-columns: repeat(3, 1fr);
  }

  .card-footer {
    font-size: 14px;
    padding: 12px 10px;
  }
}

@media screen and (max-width: 768px) {
  .category_content {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }
  .card {
    height: 200px;
    font-size: 12px;
  }
  .card-footer {
    font-size: 12px;
    padding: 10px 10px;
  }
}

@media screen and (max-width: 700px) {
  .category_content {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  .card {
    font-size: 14px;
  }
  .card-footer {
    font-size: 14px;
    padding: 12px 10px;
  }
}
@media screen and (max-width: 620px) {
  .category_content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
@media screen and (max-width: 576px) {
  .category_content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  .card {
    height: auto;
    font-size: 14px;
  }
  .card-footer {
    font-size: 14px;
    padding: 12px 10px;
  }
}

@media screen and (max-width: 476px) {
  .category_content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  .card-footer {
    font-size: 13px;
    padding: 10px;
  }
}
@media screen and (max-width: 400px) {
  .category_content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  .card {
    height: auto;
  }
  .card-footer {
    font-size: 13px;
    padding: 10px;
  }
}
@media screen and (max-width: 360px) {
  .category_content {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-footer {
    font-size: 13px;
    padding: 10px;
  }
}
@media screen and (max-width: 300px) {
  .category_content {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-footer {
    font-size: 13px;
    padding: 10px;
  }
}
