

/* new css*/

/* Designing for scroll-bar */
/* ::-webkit-scrollbar {
  width: 2px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 5px;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  /* border-radius: 4px; */
  /* background: #adadad; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #888888; */
}
.category_menu {
  position: relative;
  z-index: 10;
  /* font-family: "Afacad", sans-serif; */
  /* font-family: 'Bellefair', serif; */
  /* font-family: 'Comfortaa', sans-serif; */
  /* font-family: 'DM Sans', sans-serif; */
  /* font-family: 'Exo 2', sans-serif; */
  /* font-family: 'Inclusive Sans', sans-serif; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Kurale', serif; */
  /* font-family: 'Laila', serif; */
  /* font-family: 'League Spartan', sans-serif; */
  font-family: 'Manjari', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Prompt', sans-serif; */
  /* font-family: 'Rajdhani', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Rubik', sans-serif; */
  /* font-family: 'Sarala', sans-serif; */
  /* font-family: 'Signika Negative', sans-serif; */
  /* font-family: 'Space Grotesk', sans-serif; */
  /* font-family: 'Varela Round', sans-serif; */
}
.category_menu_content {
  height: 400px;
  padding-left: 0rem;
  padding-top: 4px;
  list-style: none;
  border: 1px solid gainsboro;
  border-radius: 5px;
  overflow-y: auto;
  /* direction: rtl; */
  /* direction: ltr; */
}

.menu_list {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 273px;
  padding: 0px 5px;
  font-size: 10px;
}
.category_icon {
  width: 20px;
  border: 1px solid gainsboro;
  border-radius: 3px;
  margin-bottom: 5px;
}
.category_icon img {
  width: 100%;
}

.menu_item {
  display: flex;
  margin-left: 10px;
  margin-top: 5px;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.menu_item p {
  font-size: 14px;
  margin-bottom: 0px;
}

.dropdown_menu {
  display: none;
  border: none;
  padding-left: 7px;
  margin-bottom: 5px;
  background-color: transparent;
}
.dropdown_menu p {
  font-size: 14px;
  margin-bottom: 0px;
}
.sub_menu {
  height: 400px;
  list-style: none;
  border-radius: 5px;
  border: 1px solid gainsboro;
  background-color: rgb(255, 255, 255);
  margin-left: 5px;
  padding-top: 5px;
  overflow-y: auto;
}
.sub_menu_list{
  display: flex;
  padding-left: 5px;
}
.menu_list:hover {
  color: #ff8800;
}
.menu_list:hover .dropdown_menu {
  display: block;
  position: absolute;
  top: 0;
  left: 96%;
  z-index: 20;
}

.sub_menu_item {
  display: flex;
  justify-content: space-between;
  width: 273px;
  padding: 2px 5px 2px 10px;
  font-size: 14px;
  color: rgb(65, 65, 65);
}
.sub_menu_item i{
  font-size: 12px;
}

.dropdown_menu_1 {
  height: 400px;
  display: none;
  padding-left: 0px;
  margin-left: -6px;
  background-color: transparent;
}
.sub_menu_1 {
  width: 700px;
  height: 400px;
  background-color: rgb(255, 255, 255);
  border: 1px solid gainsboro;
  border-radius: 5px;
  padding: 5px 20px 10px 20px;
  list-style: none;
  overflow-y: auto;
}
.mega_sub_menu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 10px;
  padding-top: 10px;
  margin-top: 10px;
}
.mega_sub_menu_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mega_sub_menu_item img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid gainsboro;
}
.mega_sub_menu_item p {
  color: rgb(48, 48, 48);
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}
.sub_menu_1 .sub_menu_item {
  font-size: 14px;
  color: #555;
}
.sub_menu_1 h5 {
  color: #555;
}

.sub_menu_list:hover .dropdown_menu_1 {
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  z-index: 20;
}
.error_msg p {
  color: black;
  font-size: 20px;
  text-align: center;
}
.sub_menu_item:hover {
  color: #ff8800;
}

ul {
  padding-left: 0rem;
}