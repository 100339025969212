/* nav css start */
.navbar-section {
  top: 0;
}

.nav {
  background: rgb(247, 247, 247);
  border-bottom: 1px solid rgb(235, 235, 235);
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px; */
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0px;
  transition: all 1s;
  z-index: 9999;
}
.nav-content {
  width: 1320px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.logo {
  width: 15%;
}

.bppshopLogo {
  width: 11em;
  cursor: pointer;
  transition: all 1s;
}
.bppshopShortLogo {
  width: 35px;
  cursor: pointer;
  display: none;
}
.bpshopsIcon {
  display: none;
  transition: all 1s;
}

.searchInput_container {
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
}

.search_input {
  width: 100%;
  background: #fff;
  border-radius: 7px;
  border: 1px solid gainsboro;
  padding: 8px 30px 8px 15px;
  font-size: 14px;
  font-family: "Space Grotesk", sans-serif;
  outline: none;
  transition: all 0.3s;
}

.searchIcon {
  position: absolute;
  right: 10px;
  /* margin-left: 92%; */
  margin-top: 10px;
  cursor: pointer;
}

/* .suggested_item_container::-webkit-scrollbar {
  display: none;
} */

.suggested_item_container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #979797;
}

.suggested_item_container {
  width: 100%;
  max-height: 35vh;
  background-color: #fff;
  border: 1px solid gainsboro;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  margin-top: 40px;
  position: absolute;
  overflow-y: scroll;
  z-index: -1;
}
.search_item_show {
  border-top: 1px solid gainsboro;
}
.suggested_item_container p {
  padding: 5px 15px 0px 15px;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer;
}

.suggested_item_container p i {
  margin-right: 5px;
}

.partner_zone {
  margin-right: 20px;
}
.partner_zone_dropdown_btn .partner_icon1 {
  width: 120px;
  padding: 9px 0px;
  background: #ffffff;
  color: #727272;
  border: 1px solid gainsboro;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.partner_zone_dropdown_btn .partner_icon2 {
  display: none;
}

.partner_zone_dropdown .dropdown-item {
  padding: 0px 10px !important;
}

.user-profile {
  font-size: 25px;
}
.user-profile img {
  width: 100%;
}
.profile_dropdown {
  width: auto;
}
.dropdown-item {
  cursor: pointer;
}

.profile_dropdown a {
  text-decoration: none;
}

/* responsive css */
@media only screen and (max-width: 1200px) {
  .nav-content {
    width: 1140px;
  }
}
@media only screen and (max-width: 768px) {
  .nav-content {
    width: 96%;
    margin: auto;
  }
  .logo {
    width: 10%;
  }
  .bppshopLogo {
    display: none;
  }
  .bppshopShortLogo {
    display: block;
  }
  .searchInput_container {
    position: relative;
    width: 75%;
    display: flex;
    justify-content: center;
  }
  .search_input {
    width: 100%;
    background: #fff;
    border: 1px solid gainsboro;
    border-radius: 30px;
    padding: 7px 30px 7px 12px;
    font-size: 14px;
    font-family: "Space Grotesk", sans-serif;
    outline: none;
    transition: all 0.3s;
  }
  .partner_zone {
    margin-right: 10px;
  }
  .partner_zone_dropdown_btn .partner_icon1 {
    display: none;
  }
  .partner_zone_dropdown_btn .partner_icon2 {
    display: block;
    font-size: 25px;
  }
  .partner_zone_dropdown .dropdown-item {
    padding: 0px 10px !important;
  }
  .user-profile {
    /* color: #ef8341; */
  }
  .profile_dropdown {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 668px) {
  .nav-content {
    margin: auto;
  }
}

@media only screen and (max-width: 576px) {
}

@media only screen and (max-width: 480px) {
}
@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 360px) {
}
