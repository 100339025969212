.brand_section{
  width: 100%;
}
.brand_section_ad_banner img{
  width: 100%;
}

.brand_section_content_container{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px ;
}

.brand_section_content{
  width: 100%;
  height: 150px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: #6e6e6e;
  transition: all 0.3s;
}
.brand_section_content:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.brand_section_content img{
  width: 100px;
  /* margin-top: 20px; */
}

.brand_section_content_container_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.brand_section_content_container_header h1{
  font-size: 1.5rem;
}
.brand_section_content_view_more_btn{
  border: none;
  background: none;
  color: #fd7e14;
  font-weight: 600;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .brand_section_content_container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px ;
  }
}
@media screen and (max-width: 576px) {
  .brand_section_content_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px ;
  }
}

@media screen and (max-width: 520px) {
  .brand_section_content_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}
@media screen and (max-width: 335px) {
  .brand_section_content_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
}