.cartDetailsView_section_overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
  position: fixed;
  top: 0;
  display: none;
}
.addToCart_loader_overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.582);
  z-index: 99;
  position: fixed;
  top: 0;
  display: none;
}
.layout_container {
  /* width: 75%; */
  width: 1320px;
  margin: auto;
  min-height: 30vh;
  padding-top: 90px;
}


@media screen and (max-width: 1200px) {
  .layout_container {
    width: 1140px;
    margin: auto;
  }
}
@media screen and (max-width: 768px) {
  .layout_container {
    width: 96%;
    margin: auto;
  }
}

