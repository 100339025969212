.all-seller-store-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
}

.all-seller-store-container::-webkit-scrollbar {
  display: none;
}
.all-seller-store-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.seller_store_content {
  height: 150px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}

.seller_store_content img {
  width: 100%;
  height: 100%;
}
.seller_store_content:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.seller_store_profile {
  width: 100%;
  height: 60%;
  position: absolute;
  margin-top: 75px;
  background: rgba(32, 31, 31, 0.741);
  color: #ffffff;
}
.seller_store_profile img {
  width: 50px;
  height: 50px;
  border: 2px solid gainsboro;
  border-radius: 50px;
  background: #fff;
  margin-top: -30px;
}
.seller_store_profile p {
  margin-top: 5px;
}
.seller_store_profile_name{
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .all-seller-store-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

@media screen and (max-width: 576px) {
  .all-seller-store-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}