
#openSidebarMenu {
  display: none;
}
#responsiveSidebarMenu {
  height: 100%;
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  margin-top: 80px;
  padding-bottom: 100px;
  background: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: transform 250ms ease-in-out;
  overflow: scroll;
  transform: translateX(-300px);
}

#responsiveSidebarMenu::-webkit-scrollbar {
  display: none;
}

#openSidebarMenu:checked ~ #responsiveSidebarMenu {
  transform: translateX(0px);
}
#responsiveSidebarMenu {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebarIconToggle {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sidebarIconToggle i {
  color: rgb(0, 0, 0);
  font-size: 20px;
}

.responsive_menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.responsive_menu li {
  border-bottom: 1px solid rgb(230, 230, 230);
  list-style: none;
}
.responsive_menu li a {
  width: 100%;
  padding: 2px 10px;
  color: rgb(65, 65, 65);
  display: block;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
}
.responsive_menu li a:hover {
  color: #ff8800;
}
.responsive_menu li img {
  width: 25px;
  border-radius: 5px;
  margin-right: 5px;
  /* padding: 2px; */
  background: #fff;
}
.responsive_menu li span {
  font-size: 12px;
  margin: 3px 2px 0px 0px !important;
}

.sidebar-Icon {
  border-radius: 2px;
}

.responsive_sub_menu,
.responsive_subSubMenu {
  border-left: 1px solid rgb(216, 216, 216);
  margin-left: -10px;
}
.responsive_sub_menu li,
.responsive_subSubMenu li {
  width: 100%;
  font-size: 12px;
  list-style: none;
  cursor: pointer;
  border-bottom: none;
}
.responsive_sub_menu li a:hover,
.responsive_subSubMenu li a:hover {
  color: #fd7e14;
}

.responsive_sub_menu li a {
  font-size: 12px;
}

.subMenuActive,
.subSubMenuActive {
  color: #fd7e14 !important;
  font-weight: 600 !important;
}

.responsive_sidebar_container {
  display: none;
}

@media screen and (max-width: 768px) {
  .responsive_sidebar_container {
    display: block;
  }

  .openSidebar {
    transform: translateX(0px);
  }
}
