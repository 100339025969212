.all_product_section_viewMore_btn {
  width: 100%;
  text-align: center;
}
.all_product_section_viewMore_btn button {
  border: none;
  padding: 10px 20px 6px 20px;
  margin-top: 40px ;
  color: rgb(255, 153, 0);
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.all_product_section_viewMore_btn button:hover {
  color: #faa919;
}

.all_product_section_viewMore_btn button span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #ffffff8c, #faa919);
  animation: animate1 2s linear infinite;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.all_product_section_viewMore_btn button span:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(to bottom, #ffffff8c, #faa919);
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

.all_product_section_viewMore_btn button span:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(to left, #ffffff8c, #faa919);
  animation: animate3 2s linear infinite;
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.all_product_section_viewMore_btn button span:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(to top, #ffffff8c, #faa919);
  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}
