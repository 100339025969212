.app_branding_section{
  margin-bottom: 50px;
   /* font-family: 'Abel', sans-serif; */
/* font-family: 'Afacad', sans-serif; */
/* font-family: 'Bad Script', cursive; */
/* font-family: 'Bellefair', serif; */
/* font-family: 'Comfortaa', sans-serif; */
/* font-family: 'DM Sans', sans-serif; */
/* font-family: 'Exo 2', sans-serif; */
/* font-family: 'Inclusive Sans', sans-serif; */
/* font-family: 'Kanit', sans-serif; */
/* font-family: 'Kurale', serif; */
font-family: 'Laila', serif;
/* font-family: 'League Spartan', sans-serif; */
/* font-family: 'Manjari', sans-serif; */
/* font-family: 'Merienda', cursive; */
/* font-family: 'Montserrat', sans-serif; */
/* font-family: 'Palanquin Dark', sans-serif; */
/* font-family: 'Prompt', sans-serif; */
/* font-family: 'Rajdhani', sans-serif; */
/* font-family: 'Roboto', sans-serif; */
/* font-family: 'Rubik', sans-serif; */
/* font-family: 'Sarala', sans-serif; */
/* font-family: 'Signika Negative', sans-serif; */
/* font-family: 'Space Grotesk', sans-serif; */
/* font-family: 'Varela Round', sans-serif; */
}
.app_branding_section_container{
  height: 250px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background: #f0f0f0;
  border-radius: 15px;
  border: 1px solid gainsboro;
  position: relative;
}
.app_branding_content_one img {
  width: 100px;
}
.app_branding_content{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.app_branding_content h1{
  font-weight: 700;
}
.app_branding_content_last{
  text-align: left;
}
.app_branding_content_social_content{
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.app_branding_content_social_content a{
  width: 30px;
  padding: 0px !important;
  color: #6d6d6d;
  font-size: 22px;
  background: rgb(255, 255, 255);
  border-radius: 7px;
  padding: 5px 10px;
  text-align: center;
  transition: all .3s;
  
}

.app_branding_content_social_content a:hover{
  color: #ff8800;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


@media screen and (max-width: 768px) {
  .app_branding_section_container{
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 20px;
  }
}
@media screen and (max-width: 576px) {
  .app_branding_section_container{
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}