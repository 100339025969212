.checkout_success_container {
  text-align: center;
  padding: 20px 0px;
}
.checkout_success_container h5{
  color: green;
}
.successImg {
  margin: 20px 0px;
}
.checkout_success_btn_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
}
.go_to_shopping_btn {
  background: #ef8341;
  border: none;
  padding: 10px 30px;
  color: #ffffff;
  border-radius: 5px;
}
.go_to_shopping_btn:hover {
  background: #e66f26;
}
.got_to_check_order_btn {
  background: rgb(151, 151, 151);
  border: none;
  padding: 10px 30px;
  color: #ffffff;
  border-radius: 5px;
}
.got_to_check_order_btn:hover {
  background: rgb(105, 105, 105);
}
