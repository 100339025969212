.product-container{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}

.product-container::-webkit-scrollbar {
  display: none;
 }
 /* Hide scrollbar for IE, Edge and Firefox */
 .product-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
 }

@media screen and (max-width: 992px) {
  .product-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 20px; */
  }
}
@media screen and (max-width: 786px) {
  .product-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 20px; */
  }
}
@media screen and (max-width: 576px) {
  .product-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 20px; */
  }
}
@media screen and (max-width: 350px) {
  .product-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-gap: 20px; */
  }
}