.footer_container_section {
  background: #eeeeee;
  padding: 40px 0px;
  margin-top: 70px;
  /* font-family: "Afacad", sans-serif; */
  /* font-family: 'Bellefair', serif; */
  /* font-family: 'Comfortaa', sans-serif; */
  /* font-family: 'DM Sans', sans-serif; */
  /* font-family: 'Exo 2', sans-serif; */
  /* font-family: 'Inclusive Sans', sans-serif; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Kurale', serif; */
  /* font-family: 'Laila', serif; */
  /* font-family: 'League Spartan', sans-serif; */
  /* font-family: 'Manjari', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Prompt', sans-serif; */
  /* font-family: 'Rajdhani', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Rubik', sans-serif; */
  /* font-family: 'Sarala', sans-serif; */
  /* font-family: 'Signika Negative', sans-serif; */
  /* font-family: 'Space Grotesk', sans-serif; */
  /* font-family: 'Varela Round', sans-serif; */
}

.footer-content {
  /* width: 75%; */
  width: 1320px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.footer_logo img{
  width: 150px;
}
.footer-content h5{
  font-size: 20px;
  color: #ff8800;
  margin: 0px 0px 15px 0px;
}
.footer-content ul{
  padding-left: 0px !important;
  
}
.footer-content ul li{
  list-style: none;
  color: #464646;
  font-size: 15px;
  cursor: pointer;
}
.footer-content ul li:hover{
  color: #ff8800;
}

.brand_address_container .logo img {
  width: 50%;
}

.address p{
  font-size: 15px;
}

.downloadApp_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}
.downloadApp_content button {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 12px;
  font-family: "Space Grotesk", sans-serif;
  background: #575757;
  color: #ffffff;
  padding: 3px 10px;
  
}
.downloadApp_content button:hover {
  /* background: #707070; */
  /* color: #ffffff; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.downloadApp_content button h6{
  font-size: 14px;
}
.downloadApp_content button img{
  margin-right: 10px;
  margin-top: -5px;
}

/* .appleIcon{
  -webkit-filter: grayscale(0.9) invert(1);
  filter: grayscale(0.9) invert(1);
} */

.newsletter_container{
  margin-top: 30px;
}
.newsletter_container h6{
  color: #ff8800;
}
.newsletter_container input{
  border: 1px solid gainsboro;
  outline: none;
  padding: 10px 35px 10px 15px;
  font-size: 15px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.newsletter_container button{
  border: none;
  outline: none;
  padding: 10px 22px;
  font-size: 15px;
  color: #ffffff;
  background: #777777;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  transition: all .3s;
}
.newsletter_container button:hover{
  background: #ff8800;
}

.get_in_touch_container {
  margin-top: 20px;
}
.get_in_touch_container h5{
  color: #ff8800;
  font-family: "Kanit", sans-serif;
}

.contact_content{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.contact_content i{
  font-size: 18px;
  color: #808080;
  background: #ffffff;
  padding: 2px 5px;
  border-radius: 50px;
}

.social_content{
  margin: 25px 0px 0px 0px;
}
.social_content i{
  font-size: 22px;
  margin-right: 7px;
  color: #808080;
  background: #ffffff;
  padding: 2px 8px;
  border-radius: 7px;
  cursor: pointer;
  transition: all .3s;
}
.social_content i:hover{
  color: #ff8800;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.special_content_list, .useful_link_list{
  line-height: 25px;
}

/* -----------Bottom Footer Style--------- */
.bottom_footer_container_section {
  background: #eeeeee;
}
.bottom_footer_content {
  width: 1320px;
  margin: auto;
  color: rgb(70, 70, 70);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  padding: 20px 0px;
  border-top: 1px solid #999999;
}
.bottom_footer_left {
  cursor: pointer;
  padding-bottom: 10px;
}
.bottom_footer_right {
  display: flex;
}
.bottom_footer_right a{
  color: rgb(70, 70, 70);
}
.bottom_term_text {
  margin-right: 15px;
  cursor: pointer;
}

.bottom_privacy_text {
  cursor: pointer;
}





@media screen and (max-width: 1200px) {
  .footer-content {
    width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .contact_content i{
    font-size: 14px;
    color: #ff8800;
    background: #ffffff;
    padding: 3px 5px;
    border-radius: 50px;
  }
  .contact_content {
    font-size: 14px;
  }


  .bottom_footer_content {
    width: 1140px;
  }
}
@media screen and (max-width: 992px) {
  .footer-content {
    /* width: 90%; */
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .contact_content i{
    font-size: 14px;
    color: #ff8800;
    background: #ffffff;
    padding: 3px 5px;
    border-radius: 50px;
  }
  .contact_content {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .footer-content {
    padding: 20px 10px;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .contact_content i{
    font-size: 14px;
    color: #ff8800;
    background: #ffffff;
    padding: 3px 5px;
    border-radius: 50px;
  }
  .contact_content {
    font-size: 14px;
  }

  .downloadApp_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    gap: 20px;
  }
  .downloadApp_content button {
    width: auto;
    display: flex;
    justify-content: space-between;
  }
  .downloadApp_content button h6{
    font-size: 12px;
  }
  .newsletter_container input{
    padding: 10px 0px 10px 10px;
    font-size: 14px;
  }
  .newsletter_container button{
    padding: 10px 15px;
    font-size: 14px;
  }

  .social_content{
    text-align: left;
  }

  .bottom_footer_content {
    width: 100%;
    margin: auto;
    /* color: white; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    padding: 20px 10px 80px 10px;
  }
}
