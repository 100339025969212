.best_selling_container {
  padding-top: 10px;
}
.best_selling_container_header{
  height: 30px;
  display: flex;
  justify-content: space-between;
}
.best_selling_container h1 {
  font-size: 1.75rem;
}
.best_selling_container_header select {
  outline: none;
  border: 1px solid gainsboro;
  color: #4e4e4e;
}