.new_arrival_section {
  margin: 50px 0px;
}
.new_arrival_section_product_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 18px;
}

.new_arrival_section_product-card-body {
  display: flex;
}
.new_arrival_section_product_content_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid gainsboro;
  margin: 0px 0px 5px 0px;
}
.new_arrival_section_product_content_header h1{
  font-size: 1.5rem;
}
.new_arrival_section_product_view_more_btn {
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 600;
  color: #ff8800;
}
.new_arrival_section_product_view_more_btn:hover {
  color: #7e7e7e;
}
.new_arrival_banner {
  width: 315px;
  height: 480px;
  overflow: hidden;
  border-radius: 10px;
}
.new_arrival_banner img {
  width: 100%;
  height: 100%;
}
.horizontal_slider_container{
  padding: 10px 50px;
  background-color: rgb(243, 243, 243);
  border-radius: 7px;
}
.new_arrival_section_product_slider{
  padding-top: 7px;
}
.new_arrival_section_productImg_container {
  width: 195px;
  height: 100%;
  border-right: 1px solid rgb(236, 236, 236);
  position: relative;
}

.new_arrival_section_product-card-body-content {
  padding: 10px ;
}
.new_arrival_section_product-card-body-content
  .product-card-body-content-unit-price {
  display: flex;
  justify-content: left;
}
.new_arrival_section_product_card_body_content_title{
  font-size: 15px;
}
.new_arrival_section_unitPrice_view {
  font-size: 14px;
  font-weight: 600;
  color: #ff8800;
}
.new_arrival_section_btn_before_add_cart,
.new_arrival_section_btn_before_add_cart_stockOut,
.new_arrival_section_btn_after_added_cart {
  border: none;
  outline: none;
  color: #ffffff;
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  transition: all 0.5s;
  font-size: 13px;
}

.new_arrival_section_btn_before_add_cart {
  /* background: #ef8341; */
  background: #ff8800;
  padding: 7px 12px;
}
.new_arrival_section_btn_before_add_cart_stockOut {
  background: #eeb18b;
  padding: 7px 12px;
}

.new_arrival_section_btn_after_added_cart {
  background: rgb(151, 151, 151) !important;
  padding: 7px 12px;
}

.new_arrival_section_quickView_AddToCart_overlay {
  position: absolute;
  background: #00000071;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
  transition: all 0.3s;
}

.quickView_AddToCart_overlay_active {
  position: absolute;
  background: #00000071;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  transition: all 0.3s;
}

.product-card:hover .new_arrival_section_quickView_AddToCart_overlay {
  display: block;
  cursor: pointer;
}

.overlayAddToCartBtn {
  color: #ffffff;
  text-align: center;
  margin-top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new_arrival_section_overlayViewCartBtn {
  color: #ffffff;
  text-align: center;
  margin-top: 37%;
  align-items: center;
  justify-content: center;
}
.new_arrival_section_overlayViewCartBtn i {
  font-size: 20px;
  margin-bottom: -10px;
}

.overlayAddToCartBtn span,
.new_arrival_section_overlayViewCartBtn span {
  font-size: 15px;
}

.overlayAddToCartBtn img {
  width: 70px;
  height: 133px;
  margin-top: -30px;
}

.new_arrival_section_product_stock_out_overlay {
  position: absolute;
  background: #00000071;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  transition: all 0.3s;
  display: none;
  color: white;
  display: block;
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  .new_arrival_section {
    margin: 30px 0px;
  }
  .new_arrival_section_product_content_header{
    margin-top: 20px;
  }
  .new_arrival_section_product_content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  

  .new_arrival_section_product-card-body {
    display: inline-block;
  }
  .new_arrival_section_productImg_container {
    width: 100%;
    height: 100%;
    padding: 5px;
    border-right: none
  }

  .new_arrival_banner{
    width: 100%;
    height: auto;
  }
  .new_arrival_banner .img1{
    display: none;
  }
  .new_arrival_banner .img2{
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .new_arrival_section_product_content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
  }

  .new_arrival_section_product-card-body {
    display: inline-block;
  }
  .new_arrival_section_productImg_container {
    width: 100%;
    height: 100%;
    padding: 5px;
    border-right: none;
    display: flex;
    justify-content: center;
  }
  .new_arrival_section_productImg_container img{
    width: 100%;
    height: 100%;
    padding: 5px;
    border-right: none;
    display: flex;
    justify-content: center;
  }
}