.deal_of_the_day_container {
  gap: 20px;
  margin: 50px 0px;
}
.deal_of_the_day_content_container{
  display: grid;
  grid-template-columns: repeat(4, minmax(17%, auto));
  grid-gap: 15px;
  overflow: hidden;
}
.deal_of_the_day_content_container > *:first-child {
  grid-column: span 1;
}
.deal_of_the_day_content_container > *:nth-child(2) {
  grid-column: span 3;
}
.deal_of_the_day_content_container > *:first-child,
.features-main .grid > *:nth-child(2) {
  grid-column: 1;
}
.deal_of_the_day_banner {
  width: 245px;
  height: 372px;
  overflow: hidden;
  border-radius: 10px;
}
.deal_of_the_day_banner img {
  width: 100%;
  height: 100%;
}
.deal_of_the_day_product_content {
  /* padding: 0px 0px 0px 10px; */
}
.deal_of_the_day_product_content_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid gainsboro;
  margin: 0px 0px 5px 0px;
}
.deal_of_the_day_product_content_header h1{
  font-size: 1.5rem;
}
.deal_of_the_day_product_view_more_btn {
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 600;
  color: #e77025;
}
.deal_of_the_day_product_view_more_btn:hover {
  color: #7e7e7e;
}

.slick-prev {
  /* left: 15px;
  z-index: 90; */
  /* background-color: rgba(126, 126, 126, 0.603); */
}
.slick-next {
  /* right: 20px;
  text-align: left; */
}
.slick-next:before,
.slick-prev:before {
  font-size: 35px;
  line-height: 0.9;
  margin-left: -5px;
  opacity: 1;
  color: #ffffff;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%; */
}


@media screen and (max-width: 768px) {
  .deal_of_the_day_container {
    margin: 30px 0px;
  }
  .deal_of_the_day_content_container{
    display: grid;
    grid-template-columns: repeat(4, minmax(17%, auto));
    grid-gap: 15px;
    overflow: hidden;
  }
  /* .deals_product_skeleton{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  } */
  /* d-flex justify-content-between */
  /* .deal_of_the_day_banner{
    width: 100%;
    height: auto;
  }
  .deal_of_the_day_banner .img1{
    display: none;
  }
  .deal_of_the_day_banner .img2{
    display: block;
  } */
  .deal_of_the_day_content_container > *:first-child {
    display: none;
  }
  .deal_of_the_day_content_container > *:nth-child(2) {
    grid-column: span 4;
  }
}