.product-card {
  position: relative;
  height: auto;
  border-radius: 7px;
  border: 1px solid gainsboro;
  background: #ffffff;
  overflow: hidden;
  transition: all 0.3s;
}

.product-card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.product-card-body {
  height: auto;
  padding: 0px 0px 5px 0px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  font-weight: 500;
}
.productImg_container {
  width: 100%;
  height: auto;
}
.product-card-body img {
  width: 100%;
  height: 100%;
}

.product-card-body-content {
  height: 30%;
  text-align: center;
}

.product-card-body-content small {
  font-size: 15px;
}

.product-card-body-content-unit-price { 
  display: flex;
  justify-content: center;
  /* margin-top: 4px; */
  margin-bottom: -3px;
}
.product-card-body-content-unit-price b {
  font-size: 15px;
  font-weight: 600;
}
.product-card-body-content-unit-price del b{
  font-size: 14px;
  font-weight: 500;
}

.unitPrice_view {
  font-size: 15px;
  font-weight: 600;
  /* margin-left: 10px; */
}
.quickView_AddToCart_overlay {
  position: absolute;
  background: #00000071;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
  transition: all 0.3s;
}

.quickView_AddToCart_overlay_active {
  position: absolute;
  background: #00000071;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  transition: all 0.3s;
}

.product-card:hover .quickView_AddToCart_overlay {
  display: block;
  cursor: pointer;
}

.overlayAddToCartBtn {
  color: #ffffff;
  text-align: center;
  margin-top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayViewCartBtn {
  color: #ffffff;
  text-align: center;
  margin-top: 50%;
  align-items: center;
  justify-content: center;
}
.overlayViewCartBtn i {
  font-size: 25px;
  margin-bottom: -10px;
}

.overlayAddToCartBtn span,
.overlayViewCartBtn span {
  font-size: 20px;
}

.overlayAddToCartBtn img {
  width: 70px;
  height: 133px;
  margin-top: -30px;
}
.product-card-footer {
  font-size: 14px;
}

.btn_before_add_cart,
.btn_before_add_cart_stockOut,
.btn_after_added_cart,
.quickViewBtn {
  border: none;
  outline: none;
  color: #ffffff;
  border-radius: 4px;
  font-family: "DM Sans", sans-serif;
  transition: all 0.5s;
}


.btn_before_add_cart,
.quickViewBtn {
  /* background: #ef8341; */
  background: #ff8800;
  padding: 5px 10px;
}
.btn_before_add_cart_stockOut{
  background: #eeb18b;
  padding: 5px 20px;
}

.btn_after_added_cart {
  background: rgb(151, 151, 151) !important;
  padding: 5px 10px;
}

.btn_before_add_cart button:hover {
  background: rgb(151, 151, 151);
}

.btn_before_add_cart button i {
  font-size: 17px;
}

.ReactModal__Overlay--after-open {
  position: fixed;
  inset: 0px;
  background-color: rgba(58, 58, 58, 0.664) !important;
  z-index: 10;
}

.modalCloseBtn {
  cursor: pointer;
  color: #474747;
  font-weight: 900;
  font-size: 20px;
  display: flex;
  justify-content: end;
}

.product_stock_out_overlay {
  position: absolute;
  background: #00000071;
  height: 324px;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  transition: all 0.3s;
  display: none;
  color: white;
  display: block;
  cursor: pointer;
}

.cardFooterBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 7px;
}

.detailsViewBtn {
  border: none;
  outline: none;
  color: #ffffff;
  border-radius: 5px;
  transition: all 0.3s;
  font-family: "DM Sans", sans-serif;
  display: none;
  background: #ef8341;
  padding: 7px 20px;
}

.addToCartGif_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addToCartGif {
  display: none;
  width: 250px;
  z-index: 99999;
}

@media screen and (max-width: 1600px) {
  .product-card-body:hover .quickView_AddToCart_overlay {
    display: block;
    cursor: pointer;
  }
  .btn_before_add_cart,
  .btn_after_added_cart {
    padding: 5px 20px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .productImg_container{
    height: auto;
  }
  .product-card-body {
    height: auto;
    padding: 5px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
    font-weight: 500;
  }
  .product-card-body img {
    height: 100%;
  }
  .product-card-body-content {
    height: 30%;
    text-align: center;
    line-height: 1.5;
  }
  .product-card-body-content-unit-price {
    text-align: center;
    font-size: 15px;
  }
  .cardFooterBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 20px;
    font-size: 12px;
  }
  .quickViewBtn {
    display: none;
  }
  .detailsViewBtn {
    padding: 7px 10px;
    font-size: 12px;
    display: block;
  }
  .btn_after_added_cart {
    padding: 7px 10px;
    font-size: 12px;
  }

  .quickView_AddToCart_overlay button {
    display: none;
  }

  .product-card-body:hover .quickView_AddToCart_overlay {
    display: block;
    cursor: pointer;
  }

  
}

@media screen and (max-width: 730px) {
  .cardFooterBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 19px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 700px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 18px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 690px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 18px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 8px;
    font-size: 12px;
  }
}

@media screen and (max-width: 676px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 15px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px;
    font-size: 12px;
  }
}
@media screen and (max-width: 640px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 14px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 5px;
    font-size: 12px;
  }
}
@media screen and (max-width: 630px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 13px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 5px;
    font-size: 12px;
  }
}
@media screen and (max-width: 615px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 12px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 5px;
    font-size: 12px;
  }
}
@media screen and (max-width: 610px) {
  .btn_after_added_cart,
  .quickViewBtn {
    padding: 7px 4px;
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 11px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 4px;
    font-size: 12px;
  }
}

@media screen and (max-width: 585px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 10px;
    font-size: 12px;
  }
  .btn_after_added_cart {
    padding: 7px 3px;
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  .cardFooterBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .btn_before_add_cart,
  .btn_after_added_cart,
  .quickViewBtn {
    padding: 7px 20px;
    font-size: 12px;
  }
}
@media screen and (max-width: 547px) {
  .btn_after_added_cart {
    padding: 7px 15px;
    font-size: 12px;
  }
}
@media screen and (max-width: 520px) {
  .btn_after_added_cart {
    padding: 7px 12px;
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .btn_after_added_cart {
    padding: 7px 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 480px) {
  .btn_after_added_cart {
    padding: 7px;
    font-size: 12px;
  }
}

@media screen and (max-width: 476px) {
  .cardFooterBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 20px;
    font-size: 11px;
  }
  .btn_after_added_cart {
    padding: 7px 10px;
    font-size: 11px;
  }
}
@media screen and (max-width: 440px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 19px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 9px;
    font-size: 10px;
  }
}
@media screen and (max-width: 430px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 18px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 9px;
    font-size: 10px;
  }
}
@media screen and (max-width: 425px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 17px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 9px;
    font-size: 10px;
  }
}
@media screen and (max-width: 420px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 16px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 8px;
    font-size: 10px;
  }
}
@media screen and (max-width: 410px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 15px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 8px;
    font-size: 10px;
  }
}

@media screen and (max-width: 395px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 14px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 8px;
    font-size: 10px;
  }
}
@media screen and (max-width: 385px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 13px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px;
    font-size: 10px;
  }
}
@media screen and (max-width: 375px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 12px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 6px;
    margin: 0px 5px 0px 0px;
    font-size: 10px;
  }
}
@media screen and (max-width: 365px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 11px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 5px;
    margin: 0px 4px 0px 0px;
    font-size: 10px;
  }
}
@media screen and (max-width: 345px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 7px 10px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 7px 5px;
    margin: 0px 5px 0px 0px;
    font-size: 10px;
  }
}
@media screen and (max-width: 340px) {
  .product-card-footer {
    padding: 7px !important;
  }
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 6px 10px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 6px 4px;
    margin: 0px 5px 0px 0px;
    font-size: 10px;
  }
}
@media screen and (max-width: 330px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 6px 9px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 6px 4px;
    margin: 0px 2px 0px 0px;
    font-size: 10px;
  }
}
@media screen and (max-width: 320px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 5px 8px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 5px 4px;
    font-size: 10px;
  }
}
@media screen and (max-width: 310px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 5px 7px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 5px 4px;
    font-size: 10px;
  }
}
@media screen and (max-width: 300px) {
  .btn_before_add_cart,
  .quickViewBtn {
    padding: 5px 6px;
    font-size: 10px;
  }
  .btn_after_added_cart {
    padding: 5px 4px;
    font-size: 10px;
  }
}
