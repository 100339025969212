.store_section{
  margin: 50px 0px;
}
.store_section_content_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
.seller_store_section_content {
  height: 150px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}
.seller_store_section_content:hover {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.seller_store_section_content img {
  width: 100%;
  height: 100%;
}
.seller_store_profile {
  width: 100%;
  height: 60%;
  position: absolute;
  margin-top: 75px;
  background: #0000008a;
  color: #ffffff;
}
.seller_store_profile img {
  width: 50px;
  height: 50px;
  border: 1px solid gainsboro;
  border-radius: 50px;
  background: #fff;
  margin-top: -30px;
}
.seller_store_profile p {
  margin-top: 5px;
}
.seller_store_profile_name{
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .store_section_content_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
}
@media screen and (max-width: 668px) {
  .store_section_content_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}
@media screen and (max-width: 576px) {
  .store_section_content_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}
@media screen and (max-width: 520px) {
  .store_section_content_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}
@media screen and (max-width: 335px) {
  .store_section_content_container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
}